import { useCreateDeviceMutation, useGetDeviceQuery, useUpdateDeviceMutation } from "@app/services/appApi";
import useAuthContext from "@core/auth/AuthContext";
import OrganizationAutocomplete from "@features/organization/OrganizationAutocomplete";
import TagSelect from "@features/tags/TagSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { stopPropagate } from "@utils/form";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { ComponentProps, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { array, object, string } from "yup";
import { IDeviceCreate, IDeviceRead, IDeviceUpdate } from "./deviceTypes";

const DEFAULT_CREATE_VALUES: IDeviceCreate = {
  name: "",
  organization_id: "",
  tag_ids: [],
};

const cleanReadDataForUpdate = (device: IDeviceRead): IDeviceUpdate => {
  const { name, tags, organization } = device;
  return { name, organization_id: organization.id, tag_ids: tags.map((tag) => tag.id), };
};

interface IDeviceFormDialogProps extends ComponentProps<typeof Dialog> {
  deviceId?: IDeviceRead["id"];
}

const schema = (t: TFunction) =>
  object({
    name: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    organization_id: string().required(t("validation.requiredField")),
    tag_ids: array()
      .of(string().required(t("validation.requiredField")))
      .required(t("validation.requiredField")),
  }).required(t("validation.requiredField"));

const DeviceFormDialog: React.FC<IDeviceFormDialogProps> = ({ deviceId, ...props }) => {
  const createMode = !deviceId;

  const { t } = useTranslation();
  const authContext = useAuthContext();

  const { control, handleSubmit, reset } = useForm<IDeviceCreate | IDeviceUpdate>({ resolver: yupResolver(schema(t)) });

  // Delay data fetching until deviceId is defined and dialog is opened
  const { data: device, isLoading: isDataLoading } = useGetDeviceQuery(deviceId ?? skipToken, { skip: !props.open });
  const [
    triggerCreate,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate },
  ] = useCreateDeviceMutation();
  const [
    triggerUpdate,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate },
  ] = useUpdateDeviceMutation();

  const canPickOrganization =
    authContext.user?.organization.max_child_orgs !== undefined && authContext.user?.organization.max_child_orgs > 0;
  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  useEffect(() => {
    if (device) {
      reset(cleanReadDataForUpdate(device));
    } else {
      reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, props.open]);

  const sendData = (data: IDeviceCreate | IDeviceUpdate) => {
    const organizationId = canPickOrganization ? data.organization_id : (authContext.user?.organization.id ?? "-1");

    if (createMode) {
      triggerCreate({ ...data, organization_id: organizationId });
    } else {
      triggerUpdate({
        id: deviceId || "",
        ...data,
        organization_id: organizationId,
      });
    }
  };

  const handleClose = useCallback(() => props.onClose?.({}, "escapeKeyDown"), [props]);

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("notifications.modelCreate.failure", { model: t("models.device") }), { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("notifications.modelCreate.success", { model: t("models.device") }), { variant: "success" });
      handleClose();
      resetCreate();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("notifications.modelUpdate.failure", { model: t("models.device") }), { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("notifications.modelUpdate.success", { model: t("models.device") }), { variant: "success" });
      handleClose();
      resetUpdate();
    }
  }, [handleClose, isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess, resetCreate, resetUpdate, t]);

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <DialogTitle>{createMode ? t("devices.formDialog.createTitle") : t("devices.formDialog.editTitle")}</DialogTitle>
      {!isDataLoading ? (
        <form onSubmit={stopPropagate(handleSubmit(sendData))}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name={"name"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    label={t("devices.formDialog.name.label")}
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
              {canPickOrganization && (
                <Controller
                  name={"organization_id"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <OrganizationAutocomplete
                      inputProps={{
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                        label: t("decices.formDialog.organization.label"),
                        required: true,
                      }}
                      autocompleteProps={{
                        fullWidth: true,
                        ...field,
                      }}
                    />
                  )}
                />
              )}
              <Controller name={"tag_ids"} control={control} render={({ field }) => <TagSelect {...field} />} />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
              {t("devices.formDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              {createMode ? t("devices.formDialog.create") : t("devices.formDialog.update")}
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DeviceFormDialog;
