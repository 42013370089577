import useAuthContext from "@core/auth/AuthContext";
import { withNoAuth } from "@core/auth/authWrappers";
import { ILoginRequest } from "@core/auth/types";
import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TFunction } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

const DEFAULT_VALUES: ILoginRequest = {
  username: "",
  password: "",
};

const schema = (t: TFunction) =>
  object({
    username: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 }))
      .email(t("validation.email.invalid")),
    password: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
  }).required(t("validation.requiredField"));

function LoginPage() {
  const { t } = useTranslation();

  const { login } = useAuthContext();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: DEFAULT_VALUES,
  });

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Log in")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(login)} sx={{ mt: 1 }}>
            <Controller
              name={"username"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  label={t("login.email.label")}
                  type="username"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name={"password"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  margin="normal"
                  label={t("login.password.label")}
                  type="password"
                  autoComplete="current-password"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
            <LoadingButton type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t("login.signIn")}
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default withNoAuth(LoginPage);
