import { Box, Stack, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { IChatMessage, IChatSemanticSearchToolReturn, ToolType } from "../../../app/services/apiTypes";
import { BotAvatar, UserAvatar } from "./ChatAvatars";
import { RelevantDocument } from "./RelevantDocument";
import { CHAT_MESSAGE_COLOR } from "./utils";

interface ChatMessageProps {
  chatMessage: IChatMessage;
  setOpenedResult: (result: IChatSemanticSearchToolReturn) => void;
}

const MessageText = styled(Typography)(() => ({
  wordBreak: "break-word",
  "& p": {
    margin: 0,
  },
}));

const ChatMessage: FC<ChatMessageProps> = ({ chatMessage, setOpenedResult }) => {
  const { t } = useTranslation();

  const isUserMessage = chatMessage.author_type === "user";
  const isAssistantMessage = chatMessage.author_type === "agent";
  const isToolMessage = chatMessage.author_type === "tool_executor";

  return isToolMessage ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mx: {
          xs: -2,
          sm: -2,
          md: -4,
          lg: -4,
          xl: -4,
        },
      }}
    >
      {chatMessage.used_tools.map((tool, index) => (
        <>
          <Typography key={index} variant="body2" fontWeight="bold" color="text.primary">
            {t(`assistant.chatMessage.tools.${tool.type}`)}
          </Typography>
          {tool.type === ToolType.SemanticSearch && tool.return && tool.return.length > 0 && (
            <Stack
              direction="row"
              gap={3}
              sx={{
                px: {
                  xs: 2,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 4,
                },
                width: "100%",
                overflowX: "auto",
              }}
            >
              {tool.return.map((result, index) => (
                <RelevantDocument key={result.src_ref_id} document={result} index={index} open={setOpenedResult} />
              ))}
            </Stack>
          )}
        </>
      ))}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: isUserMessage ? "row" : "row-reverse",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      {isUserMessage ? <UserAvatar /> : isAssistantMessage && <BotAvatar />}
      <Box
        sx={{
          bgcolor: CHAT_MESSAGE_COLOR,
          borderRadius: "10px",
          position: "relative",
          px: 3,
          py: 2,
        }}
      >
        <MessageText variant="body2" color="text.primary">
          <ReactMarkdown>{chatMessage.body || "..."}</ReactMarkdown>
        </MessageText>
        <Box
          sx={{
            position: "absolute",
            width: 0,
            top: "12px",
            left: isUserMessage ? "-16px" : undefined,
            right: isAssistantMessage ? "0" : undefined,
            height: 0,
            "&::after": {
              content: '""',
              position: "absolute",
              border: "0 solid transparent",
              borderTop: `9px solid ${CHAT_MESSAGE_COLOR}`,
              borderRadius: isUserMessage ? "0 20px 0" : "20px 0 0",
              width: "15px",
              height: "30px",
              transform: isUserMessage ? "rotate(145deg)" : "rotate(-145deg)",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export { ChatMessage };
