import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, InputAdornment, styled, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAssistantContext } from "../useAssistantContext";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatMessageList from "./ChatMessageList";
import { useChat } from "./useChat";
import { CHAT_BACKGROUND_COLOR, CHAT_BORDER_COLOR, CHAT_MESSAGE_COLOR } from "./utils";

interface IChatColumnProps {
  close: () => void;
  open: () => void;
}

const MessageEditor = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: 0,
    borderTop: `1px solid ${CHAT_BORDER_COLOR}`,
    backgroundColor: CHAT_MESSAGE_COLOR,
  },
  "& fieldset": {
    border: 0,
  },
  "& input": {
    paddingLeft: 16,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 32,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 32,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 32,
    },
    paddingRight: 16,
  },
}));

export const ChatColumn = ({ close, open }: IChatColumnProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { relevLogRecordId: relevantLogRecordId, relevDeviceId: relevantDeviceId } = useAssistantContext();

  const { isChatUninitialized, resetChat, sendMessage, chat, websocketOpen } = useChat({
    relevantDeviceId,
    relevantLogRecordId,
  });

  const [message, setMessage] = useState("");

  const handleMessageSumbit = useCallback(
    (body: string) => {
      if (!relevantDeviceId) {
        enqueueSnackbar(t("assistant.selectDeviceError"), { variant: "warning" });
        return;
      }

      setMessage("");
      open();
      sendMessage(body);
    },
    [open, relevantDeviceId, sendMessage, t],
  );

  // Auto-trigger the chat if the ?startchat= query parameter is present. This is needed to launch the assistant from a link.
  // Removes the query parameter from the URL after opening the chat.
  // This is triggered only if component is mounted, so app must ensure the component is shown when the parameter is present.
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("startchat")) {
      // Params are present, start the chat
      handleMessageSumbit(params.get("startchat") || "");
      // Remove the startchat parameter from the URL to prevent re-triggering the chat
      params.delete("startchat");
      navigate({ search: params.toString() });
    }
  }, [handleMessageSumbit, location.search, navigate]);

  return (
    <Box
      sx={{
        backgroundColor: CHAT_BACKGROUND_COLOR,
        height: "100%",
        width: {
          xs: "100%",
          sm: "100%",
          md: 600,
          lg: 600,
          xl: 600,
        },
        maxWidth: {
          xs: "100vw",
          sm: "100vw",
          md: 600,
          lg: 600,
          xl: 600,
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChatColumnHeader chat={chat} isChatUninitialized={isChatUninitialized} onClose={close} resetChat={resetChat} />
      <ChatMessageList chat={isChatUninitialized ? undefined : chat} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleMessageSumbit(message);
        }}
      >
        <MessageEditor
          fullWidth
          autoComplete="off"
          disabled={websocketOpen}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t("assistant.typeMessage")}
          value={message}
          variant="outlined"
        />
      </form>
    </Box>
  );
};
