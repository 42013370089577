import ChatIcon from "@mui/icons-material/Chat";
import { Drawer } from "@mui/material";
import Fab from "@mui/material/Fab";
import { useTheme } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChatColumn } from "./chat/ChatColumn";

const AssistantFloatingBtn: FC = () => {
  const theme = useTheme();
  const location = useLocation();

  const [openDrawer, setOpenDrawer] = useState(false);

  // Open the drawer if the ?startchat= query parameter is present. This is needed to launch the assistant from a link
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("startchat")) {
      setOpenDrawer(true);
    }
  }, [location.search]);

  const open = useCallback(() => setOpenDrawer(true), []);
  const close = useCallback(() => setOpenDrawer(false), []);

  return (
    <>
      <Fab
        color="primary"
        aria-label="show assistant"
        onClick={() => setOpenDrawer(true)}
        sx={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }}
      >
        <ChatIcon />
      </Fab>
      <Drawer
        keepMounted
        anchor="right"
        open={openDrawer}
        onClose={close}
        sx={{
          "& .MuiDrawer-paper": {
            left: {
              xs: 0,
              sm: 0,
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
          },
        }}
      >
        <ChatColumn close={close} open={open} />
      </Drawer>
    </>
  );
};

export default AssistantFloatingBtn;
