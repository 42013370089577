import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { HighlightArea, RenderHighlightsProps, Trigger, highlightPlugin } from "@react-pdf-viewer/highlight";
import React from "react";
import { IChatSemanticSearchToolReturn } from "../../app/services/apiTypes";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

export interface IPdfViewerProps {
  resource: IChatSemanticSearchToolReturn;
}

export default function PdfViewer({ resource }: IPdfViewerProps) {
  const [isDocumentLoaded, setIsDocumentLoaded] = React.useState(false);
  const [activeArea, setActiveArea] = React.useState<HighlightArea | null>(null);

  const renderHighlights = (props: RenderHighlightsProps) => {
    if (props.pageIndex === activeArea?.pageIndex) {
      return (
        <div>
          <div
            className="highlight-area"
            style={Object.assign(
              {},
              {
                border: "2px solid rgba(66, 133, 244, 0.3)", // Light blue border
                backgroundColor: "rgba(66, 133, 244, 0.1)", // Very light blue background
                borderRadius: "4px",
                boxShadow: "0 0 8px rgba(66, 133, 244, 0.2)", // Subtle glow effect
              },
              props.getCssProperties(activeArea, props.rotation),
            )}
          />
        </div>
      );
    }
    return <></>;
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [], // Only show the thumbnail tab
  });
  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  React.useEffect(() => {
    if (resource.page_idx && isDocumentLoaded) {
      setActiveArea({ height: 100, left: 0, pageIndex: resource.page_idx, top: 0, width: 100 });
    }
  }, [resource.page_idx, isDocumentLoaded, setActiveArea]);

  React.useEffect(() => {
    if (activeArea) {
      highlightPluginInstance.jumpToHighlightArea(activeArea);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeArea]);

  return (
    <Box sx={{ flex: 1, height: "1px", width: "100%" }}>
      {resource.presigned_url ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={resource.presigned_url}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
            defaultScale={SpecialZoomLevel.ActualSize}
            onDocumentLoad={(doc) => setIsDocumentLoaded(true)}
          />
        </Worker>
      ) : (
        <Typography variant="h6">No PDF available</Typography>
      )}
    </Box>
  );
}
