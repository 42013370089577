import TagSelect from "@features/tags/TagSelect";
import { ITagRead } from "@features/tags/tagTypes";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUpdateFileMutation } from "../../app/services/appApi";
import { IStorageFileRead } from "./storageFileTypes";
import { getItemName, getNewFullPath } from "./utils";

const cleanReadDataForUpdate = (storageFile: IStorageFileRead): EditFileFormProps => {
  const { tags, user_fullpath } = storageFile;

  return { name: getItemName(user_fullpath), tag_ids: tags.map((tag) => tag.id) };
};

interface EditFileFormDialogProps {
  file?: IStorageFileRead;
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

interface EditFileFormProps {
  name: string;
  tag_ids: ITagRead["id"][];
}

const EditFileFormDialog: React.FC<EditFileFormDialogProps> = ({ file, render }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const form = useForm<EditFileFormProps>();

  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateFileMutation();

  const isLoading = isUpdateLoading;

  useEffect(() => {
    if (!open) {
      if (file) {
        form.reset(cleanReadDataForUpdate(file));
      } else {
        form.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = (data: EditFileFormProps) => {
    triggerUpdate({
      id: file?.id || -1,
      tag_ids: data.tag_ids,
      user_fullpath: getNewFullPath(file?.user_fullpath ?? "", data.name),
    });
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornato con successo", { variant: "success" });
      handleClose();
    }
  }, [isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );

  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Modifica file</DialogTitle>
        <form onSubmit={form.handleSubmit(sendData)}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name="name"
                rules={{ required: true, minLength: 3 }}
                control={form.control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    label={t("fileManager.editFileFormDialog.name.label")}
                    type="text"
                    fullWidth
                    required
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && t("fileManager.editFileFormDialog.name.error")}
                    {...field}
                  />
                )}
              />
              <Controller
                name="tag_ids"
                control={form.control}
                defaultValue={[]}
                render={({ field }) => <TagSelect {...field} />}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
              {t("fileManager.editFileFormDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              {t("fileManager.editFileFormDialog.edit")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EditFileFormDialog;
