import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { default as MuiSelect, SelectProps } from "@mui/material/Select";
import { nanoid } from "nanoid";
import React from "react";
import { ISelectOption } from "./Select.types";

export interface ISelectProps<T = string | number> extends Omit<SelectProps<T>, 'label'> {
  label: string;
  options: ISelectOption<T>[];
  formControlProps?: React.ComponentProps<typeof FormControl>;
}

const Select = React.forwardRef(({ label, options, formControlProps, ...props }: ISelectProps, ref) => {
  const labelId = nanoid();

  return (
    <FormControl {...formControlProps}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect labelId={labelId} label={label} ref={ref} {...props}>
        {/* <MenuItem value="" key="emptyval">
                    <em>Nessuna selezione</em>
                </MenuItem> */}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
});

Select.displayName = "Select";

export default Select;
