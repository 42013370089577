import { useGetOrganizationsQuery } from "@app/services/appApi";
import { ListItem } from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { ComponentProps, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDebounce } from "use-debounce";

interface OrganizationAutocompleteProps {
  autocompleteProps?: Omit<
    Partial<ComponentProps<typeof MuiAutocomplete<OrganizationOption>>>,
    "value" | "onChange"
  > & {
    value: string;
    onChange: (value?: string) => void;
  };
  inputProps?: Partial<ComponentProps<typeof TextField>>;
}

interface OrganizationOption {
  id: string;
  label: string;
}

const SENTRY_OPTION_KEY = "sentry";
const SENTRY_OPTION = { id: SENTRY_OPTION_KEY, label: "" };
const ORGANIZATION_SEARCH_DEBOUNCE_MS = 1000;
const ORGANIZATION_SEARCH_PAGE_SIZE = 20;

const OrganizationAutocomplete: React.FC<OrganizationAutocompleteProps> = ({
  autocompleteProps: autocompletePropsRaw,
  inputProps,
}) => {
  const { value, onChange, ...autocompleteProps } = autocompletePropsRaw ?? {};

  const { t } = useTranslation();

  const [organizationQuery, setOrganizationQuery] = useState("");
  const [organizationsPagination, setOrganizationsPagination] = useState({
    pageSize: ORGANIZATION_SEARCH_PAGE_SIZE,
    page: 0,
  });

  const [debouncedOrganizationQuery] = useDebounce(organizationQuery, ORGANIZATION_SEARCH_DEBOUNCE_MS);

  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizationsQuery({
    page: organizationsPagination.page + 1,
    pageSize: organizationsPagination.pageSize,
    search: debouncedOrganizationQuery,
    infiniteScroll: true,
    showMyOrg: true,
  });

  const hasMoreOrganizations = !organizations || organizations.results.length < organizations.count;
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoadingOrganizations,
    hasNextPage: hasMoreOrganizations,
    onLoadMore: () => {
      if (organizationQuery !== debouncedOrganizationQuery) {
        return;
      }

      setOrganizationsPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    },
  });

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: OrganizationOption | null) => {
      onChange?.(newValue?.id);
    },
    [onChange],
  );

  const handleRenderOption = useCallback(
    (optionProps: React.HTMLAttributes<HTMLLIElement> & { key: any }, option: OrganizationOption) => {
      const { id, label } = option;

      if (id === SENTRY_OPTION_KEY) {
        return (
          (hasMoreOrganizations || isLoadingOrganizations) && (
            <ListItem ref={sentryRef} key={SENTRY_OPTION_KEY}>
              {t("organizations.OrganizationAutocomplete.loading")}
            </ListItem>
          )
        );
      }

      return (
        <ListItem {...optionProps} key={id}>
          {label}
        </ListItem>
      );
    },
    [hasMoreOrganizations, isLoadingOrganizations, sentryRef, t],
  );

  const organizationsAutocompleteOptions = useMemo(
    () => organizations?.results.map((org) => ({ id: org.id, label: org.name })),
    [organizations],
  );

  const selectedOrganization = useMemo(
    () => organizationsAutocompleteOptions?.find((org) => org.id === value) ?? null,
    [organizationsAutocompleteOptions, value],
  );

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      autoComplete={false}
      filterOptions={(filterOptions) => {
        if (hasMoreOrganizations) {
          filterOptions.push(SENTRY_OPTION);
        }

        return filterOptions;
      }}
      inputValue={organizationQuery}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      ListboxProps={{ ref: rootRef }}
      onInputChange={(_, value) => {
        setOrganizationsPagination({ pageSize: ORGANIZATION_SEARCH_PAGE_SIZE, page: 0 });
        setOrganizationQuery(value);
      }}
      onChange={handleChange}
      options={organizationsAutocompleteOptions ?? []}
      renderInput={(internalInputProps) => (
        <TextField
          {...internalInputProps}
          {...inputProps}
          type="text"
          autoComplete="off"
          inputProps={{
            ...internalInputProps.inputProps,
            ...inputProps?.inputProps,
            autocomplete: "off",
            "data-lpignore": "true",
            "data-1p-ignore": "",
          }}
        />
      )}
      renderOption={handleRenderOption}
      value={selectedOrganization}
    />
  );
};

export default OrganizationAutocomplete;
