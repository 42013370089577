import { Box, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { Helmet } from "react-helmet";
import { AppLogo } from "../../core/layout/nav";

interface ILoadingOverlayProps {
  isError?: boolean;
}

const LOGO_SIZE = 100;

/**
 * This component is rendered on top of the app when the app is loading.
 */
export default function LoadingOverlay({ isError }: ILoadingOverlayProps) {
  const theme = useTheme();

  const poweredByImg = (
    <img
      src="/static/logo/powered-by-mnet-dark.svg"
      alt="Powered by Mastranet AI"
      style={{ width: "100%", maxWidth: "200px" }}
    />
  );

  return (
    <>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <Backdrop
        sx={{
          color: theme.app.nav.textColor,
          backgroundColor: theme.app.nav.backgroundColor,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open
      >
        <Stack alignItems="center" gap={1}>
          <AppLogo
            sx={{
              width: LOGO_SIZE,
              height: LOGO_SIZE,
              borderRadius: LOGO_SIZE,
            }}
          />
          <Typography variant="h5">
            <b>{theme.app.name}</b>
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {isError ? "Server is not responding. Please try again later." : "Loading..."}
          </Typography>
        </Stack>
        {/* Disclaimer anchored to bottom of page */}
        {theme.app.poweredBy.showInLoading && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              color: theme.app.nav.textColor,
              textAlign: "center",
            }}
          >
            {theme.app.poweredBy?.url?.length > 0 ? (
              // Render clickable "powered by" logo
              <a
                href={theme.app.poweredBy.url}
                target="_blank"
                rel="noreferrer"
                style={{ display: "block", margin: "auto", width: "100%", maxWidth: "200px" }}
              >
                {poweredByImg}
              </a>
            ) : (
              // Render non-clickable "powered by" logo
              poweredByImg
            )}
          </Box>
        )}
      </Backdrop>
    </>
  );
}
