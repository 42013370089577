import { useAssistantContext } from "@features/assistant/useAssistantContext";
import Select from "@features/input/select/Select";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useGetDevicesQuery } from "../../app/services/appApi";
import { IDeviceRead } from "./deviceTypes";
import { SelectChangeEvent } from "@mui/material/Select";

export default function DeviceSelect() {
  const { relevDeviceId, setRelevDeviceId } = useAssistantContext();

  const { data: devices, isLoading: isLoadingDevices } = useGetDevicesQuery({
    page: 1,
    pageSize: 100,
    search: "",
  });

  const options = useMemo(
    () =>
      devices?.results?.map((device: IDeviceRead) => ({
        value: device.id,
        label: device.name,
      })),
    [devices],
  );

  // If there's only one device, select it by default
  useEffect(() => {
    if (!!options && options.length === 1 && (relevDeviceId === undefined || relevDeviceId.trim().length === 0)) {
      setRelevDeviceId(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return isLoadingDevices ? (
    <Typography variant="body2" color="textSecondary">
      {t("devices.select.loading")}
    </Typography>
  ) : !options || options.length === 0 ? (
    <Typography variant="body2" color="textSecondary">
      {t("devices.select.noResults")}
    </Typography>
  ) : (
    <Select
      label={t("devices.select.label")}
      options={options}
      value={relevDeviceId || ""}
      onChange={(e: SelectChangeEvent<string | number>) => setRelevDeviceId(e.target.value as IDeviceRead["id"])}
      formControlProps={{ sx: { mt: 3, maxWidth: "250px" }, fullWidth: true }}
    />
  );
}
