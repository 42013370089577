import { IStorageFileRead } from "./storageFileTypes";

const DIRECTORY_ENDING = "/.directory";

export const DraggableItemTypes = {
  Directory: "directory",
  File: "file",
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const isDirectory = (path: string) => {
  return path.endsWith(DIRECTORY_ENDING);
};

export const getItemName = (path: string) => {
  if (!isDirectory(path)) {
    return path.split("/").pop() ?? "";
  } else {
    return (
      path
        .substring(0, path.length - DIRECTORY_ENDING.length)
        .split("/")
        .pop() ?? ""
    );
  }
};

export const getDirectoryCleanPath = (path: string) => {
  if (isDirectory(path)) {
    return path.substring(0, path.length - DIRECTORY_ENDING.length);
  } else {
    return path;
  }
};

export const getNewFullPath = (originalPath: string, editedName: string) => {
  if (!isDirectory(originalPath)) {
    const splittedPath = originalPath.split("/");
    splittedPath.pop();

    return `${splittedPath.join("/")}/${editedName}`;
  } else {
    const splittedPath = originalPath.substring(0, originalPath.length - DIRECTORY_ENDING.length).split("/");
    splittedPath.pop();

    return `${splittedPath.join("/")}/${editedName}${DIRECTORY_ENDING}`;
  }
};

export const getNewFilePathWhenMoving = (file: IStorageFileRead, directory: IStorageFileRead) => {
  if (!isDirectory(directory.user_fullpath)) {
    return file.user_fullpath;
  }

  const directoryBasePath = directory.user_fullpath.substring(
    0,
    directory.user_fullpath.length - DIRECTORY_ENDING.length,
  );
  return `${directoryBasePath}/${getItemName(file.user_fullpath)}`;
};

export const getNewDirectoryPathWhenMoving = (directoryToMove: IStorageFileRead, targetDirectory: IStorageFileRead) => {
  if (!isDirectory(targetDirectory.user_fullpath)) {
    return directoryToMove.user_fullpath;
  }

  const directoryBasePath = targetDirectory.user_fullpath.substring(
    0,
    targetDirectory.user_fullpath.length - DIRECTORY_ENDING.length,
  );
  return `${directoryBasePath}/${getItemName(directoryToMove.user_fullpath)}/.directory`;
};

export const getNewFilePathInParentDirectory = (file: IStorageFileRead) => {
  const path = file.user_fullpath;
  const pathParts = path.split("/");
  pathParts.pop();
  pathParts.pop();

  return `${pathParts.join("/")}/${getItemName(file.user_fullpath)}`;
};

export const getNewDirectoryPathInParentDirectory = (directory: IStorageFileRead) => {
  const directoryBasePath = directory.user_fullpath.substring(
    0,
    directory.user_fullpath.length - DIRECTORY_ENDING.length,
  );
  const pathParts = directoryBasePath.split("/");
  pathParts.pop();
  pathParts.pop();

  return `${pathParts.join("/")}/${getItemName(directory.user_fullpath)}/.directory`;
};

export const removeFilenameFromPath = (path: string) => {
  const parts = path.split("/");
  parts.pop();
  return parts.join("/");
};

export const readDirectory = (directory: FileSystemDirectoryEntry) => {
  return new Promise<File[]>((resolve) => {
    const reader = directory.createReader();
    let directoryFiles: File[] = [];

    const readEntries = () => {
      reader.readEntries(async (entries) => {
        if (entries.length === 0) {
          resolve(directoryFiles);
        } else {
          for (let entry of entries) {
            if (entry.isFile) {
              const fileEntry = entry as FileSystemFileEntry;
              const file: File = await new Promise((resolve) => fileEntry.file(resolve));

              if (file.type === "application/pdf") {
                const newFileName = directory.fullPath.startsWith("/")
                  ? `${directory.fullPath.substring(1)}/${file.name}`
                  : `${directory.fullPath}/${file.name}`;

                const newFile = new File([file], newFileName, { type: file.type });

                directoryFiles.push(newFile);
              }
            } else if (entry.isDirectory) {
              const nestedFiles = await readDirectory(entry as FileSystemDirectoryEntry);
              directoryFiles = directoryFiles.concat(nestedFiles);
            }
          }

          readEntries();
        }
      });
    };

    readEntries();
  });
};
