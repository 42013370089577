import {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from "@app/services/appApi";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { stopPropagate } from "@utils/form";
import { TFunction } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { ComponentProps, FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { number, object, string } from "yup";
import { IOrganizationCreate, IOrganizationRead, IOrganizationUpdate } from "./organizationTypes";

const DEFAULT_CREATE_VALUES: IOrganizationCreate = {
  name: "",
  max_child_orgs: 0,
};

const cleanReadOrganizationDataForUpdate = (organization: IOrganizationRead): IOrganizationUpdate => {
  const { name, max_child_orgs } = organization;
  return { name, max_child_orgs };
};

interface IOrganizationFormDialogProps extends ComponentProps<typeof Dialog> {
  organizationId?: IOrganizationRead["id"];
}

const schema = (t: TFunction) =>
  object({
    name: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    // Max child organizations must be a non-negative integer
    max_child_orgs: number()
      .integer(t("validation.integer"))
      .min(0, t("validation.minValue", { value: 0 }))
      .required(t("validation.requiredField")),
  }).required(t("validation.requiredField"));

const OrganizationFormDialog: FC<IOrganizationFormDialogProps> = ({ organizationId, ...props }) => {
  const createMode = !organizationId;

  const { t } = useTranslation();
  const { can } = useAuthorization();

  const { control, handleSubmit, reset } = useForm<IOrganizationCreate | IOrganizationUpdate>({
    resolver: yupResolver(schema(t)),
  });

  // Delay data fetching until organizationId is defined and dialog is opened
  const { data: organization, isLoading: isDataLoading } = useGetOrganizationQuery(organizationId ?? skipToken, {
    skip: !props.open,
  });
  const [
    triggerCreate,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError, reset: resetCreate },
  ] = useCreateOrganizationMutation();
  const [
    triggerUpdate,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError, reset: resetUpdate },
  ] = useUpdateOrganizationMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (organization) {
      reset(cleanReadOrganizationDataForUpdate(organization));
    } else {
      reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, props.open]);

  const sendData = (data: IOrganizationCreate | IOrganizationUpdate) => {
    if (createMode) {
      triggerCreate(data);
    } else {
      triggerUpdate({
        id: organizationId || "",
        ...data,
      });
    }
  };

  const handleClose = useCallback(() => props.onClose?.({}, "escapeKeyDown"), [props]);

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar(t("notifications.modelCreate.failure", { model: t("models.organization") }), {
        variant: "error",
      });
    }
    if (isCreateSuccess) {
      enqueueSnackbar(t("notifications.modelCreate.success", { model: t("models.organization") }), {
        variant: "success",
      });
      handleClose();
      resetCreate();
    }
    if (isUpdateError) {
      enqueueSnackbar(t("notifications.modelUpdate.failure", { model: t("models.organization") }), {
        variant: "error",
      });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar(t("notifications.modelUpdate.success", { model: t("models.organization") }), {
        variant: "success",
      });
      handleClose();
      resetUpdate();
    }
  }, [handleClose, isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess, resetCreate, resetUpdate, t]);

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <DialogTitle>
        {createMode ? t("organizations.formDialog.createTitle") : t("organizations.formDialog.editTitle")}
      </DialogTitle>
      {!isDataLoading ? (
        <form onSubmit={stopPropagate(handleSubmit(sendData))}>
          <DialogContent>
            <Stack gap={2}>
              <Controller
                name={"name"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    label={t("organizations.formDialog.name.label")}
                    type="text"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
              {can("ORGANIZATION.MAX_CHILD_ORG", "WRITE") && (
                <Controller
                  name={"max_child_orgs"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      required
                      label={t("organizations.formDialog.maxChildOrgs.label")}
                      type="number"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: 2 }}>
            <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
              {t("organizations.formDialog.cancel")}
            </Button>
            <LoadingButton
              startIcon={<CheckOutlinedIcon />}
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              type="submit"
            >
              {createMode ? t("organizations.formDialog.create") : t("organizations.formDialog.update")}
            </LoadingButton>
          </DialogActions>
        </form>
      ) : (
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default OrganizationFormDialog;
