import { Page } from "@core/layout/page";
import { yupResolver } from "@hookform/resolvers/yup";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TagIcon from "@mui/icons-material/Tag";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TFunction } from "i18next";
import { FC, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";
import useAuthContext from "../../core/auth/AuthContext";
import { withAuth } from "../../core/auth/authWrappers";

interface FormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const DEFAULT_VALUES = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
};

const schema = (t: TFunction) =>
  object({
    oldPassword: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    newPassword: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 })),
    newPasswordConfirmation: string()
      .required(t("validation.requiredField"))
      .min(3, t("validation.minLength", { count: 3 }))
      .oneOf([ref("newPassword")], t("profile.newPasswordConfirmation.passwordsMustMatch")),
  }).required(t("validation.requiredField"));

const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuthContext();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: DEFAULT_VALUES,
  });

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("it-IT", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  const changePassword = useCallback((data: FormValues) => {
    // TODO: implement change password
  }, []);

  return (
    <Page title={t("profile.title")}>
      <Box sx={{ maxWidth: 600, mx: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 4 }}>
          <AccountCircleOutlinedIcon sx={{ fontSize: 80 }} />
          <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
            {user?.username}
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
          {t("profile.userInfo")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BusinessIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("profile.fields.organization")}:</strong> {user?.organization.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SupervisorAccountIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("profile.fields.role")}:</strong> {user?.role}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TagIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("profile.fields.organizationId")}:</strong> {user?.organization.id}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CalendarTodayIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("profile.fields.createdAt")}:</strong> {user?.created_at && formatDate(user?.created_at)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button variant="contained" color="error" onClick={logout} sx={{ minWidth: 120 }}>
            {t("profile.logout")}
          </Button>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box
          component="form"
          onSubmit={handleSubmit(changePassword)}
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t("profile.changePassword")}
          </Typography>
          <Controller
            name={"oldPassword"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                margin="normal"
                label={t("profile.oldPassword.label")}
                type="password"
                autoComplete="current-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name={"newPassword"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                margin="normal"
                label={t("profile.newPassword.label")}
                type="password"
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name={"newPasswordConfirmation"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                margin="normal"
                label={t("profile.newPasswordConfirmation.label")}
                type="password"
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
          <LoadingButton type="submit" variant="contained" sx={{ alignSelf: "center", mt: 2 }}>
            {t("profile.changePassword")}
          </LoadingButton>
        </Box>
      </Box>
    </Page>
  );
};

export default withAuth(ProfilePage);
