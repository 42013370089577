import React from "react";
import { Navigate } from "react-router-dom";
import LoadingOverlay from "../../features/auth/LoadingOverlay";
import useAuthContext from "./AuthContext";

export enum Role {
  User = "user",
}

interface IWithAuthProps {
  allowedRoles: Role[];
  // Add any other props that come with the component you want to wrap
  [key: string]: any;
}

const withAuth = <P extends object>(Component: React.ComponentType<P>) => {
  const WithAuth = ({ allowedRoles, ...props }: IWithAuthProps) => {
    const { user } = useAuthContext();

    // Check if user has the necessary permissions
    // to access the component
    // const hasPermission = (allowedRoles: Role[], userRole: Role) => {
    //   const userRoleIndex = allowedRoles.indexOf(userRole);
    //   return userRoleIndex !== -1;
    // };

    if (user === undefined) {
      return <LoadingOverlay />;
    }

    if (user === null) {
      return <Navigate replace to="/login" />;
    }

    // If user does not have the necessary permissions
    // to access the component, redirect to home page
    // if (!hasPermission(allowedRoles, token.role)) {
    //   return <Redirect to="/" />;
    // }

    // If user has the necessary permissions
    // to access the component, render it
    return <Component {...(props as P)} />;
  };

  return WithAuth;
};

interface IWithNoAuthProps {}

const withNoAuth = <P extends object>(Component: React.ComponentType<P>) => {
  const WithNoAuth = ({ ...props }: IWithNoAuthProps) => {
    const { user } = useAuthContext();

    if (user === undefined) {
      return <LoadingOverlay />;
    }

    if (user !== null) {
      return <Navigate replace to="/" />;
    }

    return <Component {...(props as P)} />;
  };

  return WithNoAuth;
};

export { withAuth, withNoAuth };
