import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { Page } from "@core/layout/page";
import { Check, CreateNewFolder, SwapVert, ArrowUpward as UploadIcon } from "@mui/icons-material";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import React, { useCallback, useMemo, useState } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import {
  useDeleteFileMutation,
  useGetFilesQuery,
  useUpdateFileMutation,
  useUploadFileMutation,
} from "../../app/services/appApi";
import { withAuth } from "../../core/auth/authWrappers";
import CreateDirectoryDialog from "./CreateDirectoryDialog";
import FileManager from "./FileManager";
import FileUploadDialog from "./FileUploadDialog";
import { IStorageFileRead } from "./storageFileTypes";
import { getDirectoryCleanPath, getItemName, isDirectory } from "./utils";

const FileManagerPage: React.FC = () => {
  const { can } = useAuthorization();
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPath, setCurrentPath] = useState("");
  const [reorderMode, setReorderMode] = useState(false);
  const [isCreateDirectoryDialogOpen, setCreateDirectoryDialog] = useState(false);
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);

  const { data: files, isLoading, refetch } = useGetFilesQuery({ path: currentPath }, { pollingInterval: 3000 });
  const [deleteFile, { isLoading: isDeletingFile, originalArgs: deleteFileArgs }] = useDeleteFileMutation();
  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();
  const [updateFile, { isLoading: isUpdatingFile, originalArgs: updateFileArgs }] = useUpdateFileMutation();

  const deleteFileId = deleteFileArgs?.id;
  const updateFileId = updateFileArgs?.id;

  const handleCreateDirectory = useCallback(
    async (name: string) => {
      try {
        await uploadFile({ path: `${currentPath}/${name}/.directory`, file: new File([""], ".directory") }).unwrap();
        enqueueSnackbar(t("fileManager.createDirectoryDialog.success"), { variant: "success" });
        refetch();
      } catch (error) {
        enqueueSnackbar(t("fileManager.createDirectoryDialog.failure"), { variant: "error" });
      }
    },
    [currentPath, refetch, t, uploadFile],
  );

  const handleDelete = useCallback(
    async (file: IStorageFileRead) => {
      try {
        await deleteFile({ id: file.id }).unwrap();
        enqueueSnackbar(
          isDirectory(file.user_fullpath)
            ? t("fileManager.deleteDirectory.success")
            : t("fileManager.deleteFile.success"),
          { variant: "success" },
        );
        refetch();
      } catch (error) {
        enqueueSnackbar(
          isDirectory(file.user_fullpath)
            ? t("fileManager.deleteDirectory.failure")
            : t("fileManager.deleteFile.failure"),
          { variant: "error" },
        );
      }
    },
    [deleteFile, refetch, t],
  );

  const handleUpload = useCallback(
    async (file: File, silent = false) => {
      try {
        const path = `${currentPath}/${file.name}`;

        await uploadFile({ path, file }).unwrap();
        if (!silent) {
          enqueueSnackbar(t("fileManager.uploadFile.success"), { variant: "success" });
        }
        refetch();
      } catch (error) {
        if (!silent) {
          enqueueSnackbar(t("fileManager.uploadFile.error"), { variant: "error" });
        }
      }
    },
    [currentPath, refetch, t, uploadFile],
  );

  const handleMoveFile = useCallback(
    async (file: IStorageFileRead, newPath: string) => {
      try {
        await updateFile({ id: file.id, tag_ids: file.tags.map((tag) => tag.id), user_fullpath: newPath }).unwrap();
        enqueueSnackbar(t("fileManager.moveFile.success"), { variant: "success" });
        refetch();
      } catch (error) {
        enqueueSnackbar(t("fileManager.moveFile.error"), { variant: "error" });
      }
    },
    [refetch, t, updateFile],
  );

  // Sort directories first, then files by name
  const sortedFiles = useMemo(
    () =>
      files?.slice().sort((a, b) => {
        if (isDirectory(a.user_fullpath) && !isDirectory(b.user_fullpath)) {
          return -1;
        }
        if (!isDirectory(a.user_fullpath) && isDirectory(b.user_fullpath)) {
          return 1;
        }
        return getItemName(a.user_fullpath).localeCompare(getItemName(b.user_fullpath));
      }),
    [files],
  );

  const actions = can("CREATE", "FILE") && (
    <>
      {isMobile && (
        <Button startIcon={reorderMode ? <Check /> : <SwapVert />} onClick={() => setReorderMode((prev) => !prev)}>
          {reorderMode ? t("fileManager.finish") : t("fileManager.reorder")}
        </Button>
      )}
      <Button startIcon={<CreateNewFolder />} onClick={() => setCreateDirectoryDialog(true)}>
        {t("fileManager.newDirectory")}
      </Button>
      <Button variant="contained" startIcon={<UploadIcon />} onClick={() => setUploadDialogOpen(true)}>
        {t("fileManager.upload")}
      </Button>
    </>
  );

  return (
    <DndProvider options={HTML5toTouch}>
      <Page
        title={currentPath.trim().length > 0 ? getDirectoryCleanPath(currentPath) : t("fileManager.title")}
        actions={actions}
      >
        <FileManager
          currentPath={currentPath}
          deleteFile={handleDelete}
          deletingFileId={isDeletingFile ? deleteFileId : undefined}
          files={sortedFiles}
          isLoadingFiles={isLoading}
          moveFile={handleMoveFile}
          movingFileId={isUpdatingFile ? updateFileId : undefined}
          refetchFiles={refetch}
          reorderMode={!isMobile || reorderMode}
          setCurrentPath={setCurrentPath}
        />
        <CreateDirectoryDialog
          isCreatingDirectory={isUploadingFile}
          open={isCreateDirectoryDialogOpen}
          onClose={() => setCreateDirectoryDialog(false)}
          onCreate={handleCreateDirectory}
        />
        <FileUploadDialog
          isUploadingFile={isUploadingFile}
          open={isUploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          onUpload={handleUpload}
        />
      </Page>
    </DndProvider>
  );
};

export default withAuth(FileManagerPage);
