import useAuthContext from "@core/auth/AuthContext";
import { useAuthorization } from "@core/auth/authz/useAuthorization";
import { INavItem } from "@core/layout/nav/NavItem";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import EditNoteIcon from "@mui/icons-material/EditNote";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TagIcon from "@mui/icons-material/Tag";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { NavDrawer } from "./core/layout/nav";
import AssistantFloatingBtn from "./features/assistant/AssistantFloatingBtn";

export const AppLayout: FC = () => {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const theme = useTheme();
  const authContext = useAuthContext();

  const navItems = useMemo(
    (): INavItem[] => [
      {
        icon: <PrecisionManufacturingOutlinedIcon />,
        text: t("layout.device", { count: 2 }),
        url: "/devices",
        isVisible: can("READ", "DEVICE"),
        position: "top",
      },
      {
        icon: <EditNoteIcon />,
        text: t("layout.note", { count: 2 }),
        url: "/notes",
        isVisible: authContext.isLoggedIn,
        position: "top",
      },
      {
        icon: <FolderOpenOutlinedIcon />,
        text: t("layout.fileManager"),
        url: "/files",
        isVisible: can("READ", "FILE"),
        position: "top",
      },
      {
        icon: <BusinessIcon />,
        text: t("layout.organization", { count: 2 }),
        url: "/organizations",
        isVisible: can("READ", "ORGANIZATION") && !!authContext.user?.organization?.max_child_orgs,
        position: "top",
      },
      {
        icon: <PeopleAltOutlinedIcon />,
        text: t("layout.user", { count: 2 }),
        url: "/users",
        isVisible: can("READ", "USER"),
        position: "top",
      },
      {
        icon: <TagIcon />,
        text: t("layout.tags", { count: 2 }),
        url: "/tags",
        isVisible: can("READ", "TAG"),
        position: "top",
      },
      {
        icon: <BugReportOutlinedIcon />,
        text: t("layout.debug"),
        url: "/debug",
        isVisible: authContext.isLoggedIn && process.env.NODE_ENV === "development",
        position: "top",
      },
      {
        icon: <AccountCircleOutlinedIcon />,
        text: authContext.user ? authContext.user.username : t("layout.login"),
        url: authContext.user ? "/profile" : "/login",
        position: "bottom",
      },
      {
        icon: <HelpOutlineIcon />,
        text: t("layout.contactUs"),
        url: theme.app.contactUrl,
        isVisible: true,
        position: "bottom",
      },
      {
        icon: <SettingsOutlinedIcon />,
        text: t("layout.settings"),
        url: "/settings",
        isVisible: authContext.isLoggedIn,
        position: "bottom",
      },
    ],
    [authContext.isLoggedIn, authContext.user, can, t, theme.app.contactUrl],
  );

  return (
    <>
      <Helmet>
        <title>{theme.app.title}</title>
        <link rel="icon" type="image/png" sizes="16x16" href={theme.app.logo.favicon.icon16} />
        <link rel="icon" type="image/png" sizes="32x32" href={theme.app.logo.favicon.icon32} />
        <link rel="apple-touch-icon" sizes="180x180" href={theme.app.logo.favicon.appleTouchIcon} />
        <link rel="mask-icon" href={theme.app.logo.favicon.maskIcon} color="#5bbad5" />
      </Helmet>
      <NavDrawer items={navItems}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            p: { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 },
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </Box>
        {authContext.isLoggedIn && <AssistantFloatingBtn />}
      </NavDrawer>
    </>
  );
};
