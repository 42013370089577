import PersonIcon from "@mui/icons-material/Person";
import { styled, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { ComponentProps, FC } from "react";

export const CHAT_AVATAR_SIZE = 40;
export const LARGE_CHAT_AVATAR_SIZE = 80;

const StyledAvatar = styled(Avatar)(() => ({
  height: CHAT_AVATAR_SIZE,
  width: CHAT_AVATAR_SIZE,
  aspectRatio: "1",
  padding: 8,
  backgroundColor: "white",
}));

const LargeStyledAvatar = styled(StyledAvatar)(() => ({
  width: LARGE_CHAT_AVATAR_SIZE,
  height: LARGE_CHAT_AVATAR_SIZE,
}));

interface AvatarWithIconProps extends ComponentProps<typeof Avatar> {
  large?: boolean;
}

const AvatarWithIcon: FC<AvatarWithIconProps> = ({ large, ...rest }) => {
  const AvatarComponent = large ? LargeStyledAvatar : StyledAvatar;

  return <AvatarComponent {...rest} />;
};

interface BotAvatarProps {
  large?: boolean;
}

const BotAvatar: FC<BotAvatarProps> = ({ large = false }) => {
  const theme = useTheme();
  const logoSrc = theme.app.assistant.logo.src;

  return <AvatarWithIcon large={large} src={logoSrc} />;
};

const UserAvatar: FC = () => (
  <AvatarWithIcon>
    <PersonIcon color="primary" />
  </AvatarWithIcon>
);

export { BotAvatar, UserAvatar };
