import LoadingOverlay from "@features/auth/LoadingOverlay";
import { Enforcer, newEnforcer, newModelFromString, StringAdapter } from "casbin";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useGetPolicyQuery } from "../../../app/services/appApi";
import useAuthContext from "../AuthContext";
import { IAuthzSub } from "../types";

type Policy = [string, string, string, string][];

interface AuthzContextType {
  enforcer: Enforcer | null;
  sub: IAuthzSub | null | undefined;
}

export const AuthzContext = createContext<AuthzContextType | undefined>(undefined);

interface MyAuthzProviderProps {
  children: ReactNode;
}

function convertPolicyToString(policy: Policy): string {
  const policyCsv = policy.map((row) => ["p", ...row].join(",")).join("\n");
  // console.debug(policyCsv);
  return policyCsv;
}

export default function MyAuthzProvider({ children }: MyAuthzProviderProps) {
  const { user } = useAuthContext();

  const [enforcer, setEnforcer] = useState<Enforcer | null>(null);
  const { data: policyData, isLoading, error } = useGetPolicyQuery();

  useEffect(() => {
    (async () => {
      if (policyData && !isLoading) {
        //     const modelContent = `
        // [request_definition]
        // r = sub, obj, act

        // [policy_definition]
        // p = sub, obj, act

        // [policy_effect]
        // e = some(where (p.eft == allow))

        // [matchers]
        // m = r.sub == p.sub && r.obj == p.obj && r.act == p.act
        // `;
        const modelContent = policyData.model;

        //     const policyContent = `
        // p, alice, data1, read
        // p, bob, data2, write
        // `;
        const policyContent = convertPolicyToString(policyData.policy);

        // console.debug("Model content: ", modelContent);
        // console.debug("Policy content: ", policyContent);

        const model = newModelFromString(modelContent);
        const policyAdapter = new StringAdapter(policyContent);

        const e = await newEnforcer(model, policyAdapter);

        setEnforcer(e);
      }
    })();
  }, [policyData, isLoading]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return <AuthzContext.Provider value={{ sub: user?.authz_sub, enforcer }}>{children}</AuthzContext.Provider>;
}
