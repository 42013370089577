import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IAuthContextProps } from "../../auth/AuthContext";
import NavDrawerHeader from "./NavDrawerHeader";
import NavItem, { INavItem } from "./NavItem";
import PoweredByNavItem from "./PoweredByNavItem";

export type RenderNavItemsProps = (props: { authContext: IAuthContextProps }) => INavItem[];

export interface INavDrawerProps {
  children: React.ReactNode;
  items: INavItem[];
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.app.nav.backgroundColor,
  color: theme.app.nav.textColor,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.app.nav.backgroundColor,
  color: theme.app.nav.textColor,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function NavDrawer({ children, items }: INavDrawerProps) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [expanded, setExpanded] = useState<boolean>(!isMobile);

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    }
  }, [isMobile]);

  const handleDrawerToggle = useCallback(() => setExpanded((prev) => !prev), []);

  const visibleItems = useMemo(() => items.filter(({ isVisible = true }) => isVisible), [items]);
  const topNavItems = useMemo(() => visibleItems.filter(({ position = "top" }) => position === "top"), [visibleItems]);
  const bottomNavItems = useMemo(
    () => visibleItems.filter(({ position = "top" }) => position === "bottom"),
    [visibleItems],
  );

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Drawer variant="permanent" open={expanded}>
        <NavDrawerHeader onClick={handleDrawerToggle} expanded={expanded} />
        <Stack direction="column" justifyContent="space-between" height="100%">
          <List>
            {topNavItems.map((item) => (
              <NavItem item={item} expanded={expanded} key={item.url} />
            ))}
          </List>
          <List sx={{ borderLeft: "3px solid white" }}>
            <PoweredByNavItem open={expanded} />
            {bottomNavItems.map((item) => (
              <NavItem item={item} expanded={expanded} key={item.url} />
            ))}
            {!isMobile && (
              <NavItem
                item={{
                  icon: expanded ? (
                    <KeyboardDoubleArrowLeftIcon sx={{ color: theme.app.nav.textColor }} />
                  ) : (
                    <KeyboardDoubleArrowRightIcon sx={{ color: theme.app.nav.textColor }} />
                  ),
                  text: expanded ? "Collapse" : "Expand",
                  onClick: handleDrawerToggle,
                  position: "bottom",
                }}
                expanded={expanded}
              />
            )}
          </List>
        </Stack>
      </Drawer>
      {children}
    </Box>
  );
}
