import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PoweredByNavItemProps {
  open: boolean;
}

export default function PoweredByNavItem({ open }: PoweredByNavItemProps) {
  const theme = useTheme();

  const poweredByImg = (
    <img
      src={open ? "/static/logo/powered-by-mnet-dark-3.svg" : "/static/logo/mnet-square-dark.svg"}
      alt="Powered by Mastranet AI"
      style={{ width: "100%" }}
    />
  );

  return (
    theme.app.poweredBy.showInNav && (
      <Box
        style={{
          paddingLeft: open ? "20px" : "15px",
          paddingRight: open ? "20px" : "15px",
          paddingBottom: "80px",
          paddingTop: "4px",
          height: "30px",
          maxWidth: open ? "180px" : "65px",
          textAlign: "left",
        }}
      >
        {theme.app.poweredBy?.url?.length > 0 ? (
          // Render clickable "powered by" logo
          <a href={theme.app.poweredBy.url} target="_blank" rel="noreferrer">
            {poweredByImg}
          </a>
        ) : (
          // Render non-clickable "powered by" logo
          poweredByImg
        )}
      </Box>
    )
  );
}
