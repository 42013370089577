import ResourceViewer from "@features/viewer/ResourceViewer";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { IChat, IChatSemanticSearchToolReturn } from "../../../app/services/apiTypes";
import { ChatMessage } from "./ChatMessage";

interface ChatMessageListProps {
  chat?: IChat;
}

export default function ChatMessageList({ chat }: ChatMessageListProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [openedResult, setOpenedResult] = useState<IChatSemanticSearchToolReturn>();

  const messages = chat?.messages || [];

  // Scroll to the bottom of the chat history whenever a new message is added
  useEffect(() => {
    ref.current?.scrollTo({
      left: 0,
      top: ref.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages.length]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          gap: 2,
          px: {
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
          },
          py: 2,
        }}
      >
        {messages.map((chatMessage, index) => (
          <ChatMessage key={index} chatMessage={chatMessage} setOpenedResult={setOpenedResult} />
        ))}
      </Box>
      <ResourceViewer open={!!openedResult} onClose={() => setOpenedResult(undefined)} resource={openedResult} />
    </>
  );
}
