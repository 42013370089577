import { Page } from "@core/layout/page";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "../../core/auth/authWrappers";
import LanguageSelect from "./LanguageSelect";

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("settings.title")}>
      <Container maxWidth="sm">
        <Paper elevation={0} sx={{ p: 4, mt: 4 }}>
          <Stack spacing={3}>
            <Box display="flex" alignItems="center">
              <TranslateIcon sx={{ mr: 1 }} />
              <Typography variant="h5" component="h2" gutterBottom>
                {t("settings.language.title")}
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {t("settings.language.message")}
            </Typography>
            <LanguageSelect />
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
};

export default withAuth(ProfilePage);
