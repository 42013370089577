import { ITopKResult } from "@app/services/apiTypes";
import {
  useCreateLogRecordMutation,
  useDebugInfoQuery,
  useForceReindexAllFilesMutation,
  useForceReindexAllNotesMutation,
} from "@app/services/appApi";
import { store } from "@app/store";
import { Page } from "@core/layout/page";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Input } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import CasbinTest from "./CasbinTest";

const DeveloperPage: React.FC = () => {
  const { data } = useDebugInfoQuery();
  const [createLogRecord] = useCreateLogRecordMutation();

  const [reindexFiles, { isLoading, isError, isSuccess }] = useForceReindexAllFilesMutation();

  const [reindexNotes, { isLoading: isNotesLoading, isError: isNotesError, isSuccess: isNotesSuccess }] =
    useForceReindexAllNotesMutation();

  const [message, setMessage] = useState("");
  enum Status {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
  }
  const [level, setLevel] = useState<Status>(Status.INFO);

  // Display notification when reindex is done or failed
  useEffect(() => {
    if (isSuccess || isNotesSuccess) {
      enqueueSnackbar("Reindex done", { variant: "success" });
    }
    if (isError || isNotesError) {
      enqueueSnackbar("Reindex failed", { variant: "error" });
    }
  }, [isSuccess, isError, isNotesSuccess, isNotesError]);

  const [loremIpsum, setLoremIpsum] = useState<"true" | "false">(
    localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false",
  );

  useEffect(() => {
    if (loremIpsum === "true") {
      localStorage.setItem("dbgLoremChat", "true");
    }
    if (loremIpsum === "false") {
      localStorage.setItem("dbgLoremChat", "false");
    }
  }, [loremIpsum]);

  const disp = store.dispatch;

  const resources: ITopKResult[] = [
    {
      content:
        'questo articolo potrebbe \nessere interessato anche a questo" \npossono rivelarsi strategie vincenti.0 35\n1\n06\n\fMastranet AI S.R.L \n\nP.IVA: 04743880165 \nVia Pasubio, 5, 24044 Dalmine BG\n\nCell: +39 3281957608\nemail: info@mastranet.ai\nW e e xtr ac t the \nhidden v alue\nof y our data.',
      page_idx: 4,
      presigned_url:
        "/storage/serve/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWNrZW5kX3BhdGgiOiJzdG9yYWdlX2ZpbGVzL29yZ18xL01hc3RyYW5ldEFJX3doaXRlcGFwZXJfMjAyNC5wZGYiLCJleHAiOjE3MjcxMDEwMjh9.pNFFEbvsUMVccCoZdapZjyTKU9nt_Pl3JKk36WEgP_8",
      score: 0.9862046,
      src_ref_id: "71",
      src_ref_type: "STORAGE_FILE",
      title: "MastranetAI whitepaper 2024.pdf",
    },
    {
      content:
        "audio di tutte \nle tipologia da voci registrate \nfino a suoni o rumori di \nmacchinariElaborazione dati testuali\nElaborazioni immagini\nElaborazione serie storicheElaborazione audioAI per il tuo business\nApplicazioni pr atic he dell\u2019 AI per aziende\nMastranet AI\n\fIsp ezione v isi v a\nanalisi p arametri di\np rocessoGli algoritmi di Machine Learning \npossono essere addestrati con una \nconoscenza specifica del prodotto e, di \nconseguenza, sono in grado di rilevare \nautomaticamente anomalie senza \nrichiedere un controllo manuale.\n\u00c8 possibile sfruttare i parametri di \nprocesso dei macchinari industriali al \nfine di trovare correlazioni tra di essi che \npermettono di stimarne l'andamento nel \nfuturo. Una volta trovate le correlazioni, \u00e8 ",
      page_idx: 1,
      presigned_url:
        "/storage/serve/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWNrZW5kX3BhdGgiOiJzdG9yYWdlX2ZpbGVzL29yZ18xL01hc3RyYW5ldEFJX3doaXRlcGFwZXJfMjAyNC5wZGYiLCJleHAiOjE3MjcxMDEwMjh9.pNFFEbvsUMVccCoZdapZjyTKU9nt_Pl3JKk36WEgP_8",
      score: 0.18952109,
      src_ref_id: "71",
      src_ref_type: "STORAGE_FILE",
      title: "MastranetAI whitepaper 2024.pdf",
    },
  ];

  return (
    <Page title="Debug page">
      <Stack spacing={2}>
        {/* <UserFormDialog userId={1} /> */}
        <Typography variant="h6">Frontend info</Typography>
        <Typography variant="body1" component="p">
          Version: {process.env.REACT_APP_VERSION}
          <br />
          Environment: {process.env.NODE_ENV}
        </Typography>
        <Typography variant="h6">Backend info</Typography>
        <Typography variant="body1" component="p">
          {JSON.stringify(data)}
        </Typography>
        <Typography variant="h6">Generate logrecord from machinery</Typography>

        <Input
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputProps={{ "aria-label": "description" }}
        />

        <Select value={level} onChange={(e) => setLevel(e.target.value as Status)}>
          <MenuItem value={Status.INFO}>Info</MenuItem>
          <MenuItem value={Status.WARNING}>Warning</MenuItem>
          <MenuItem value={Status.ERROR}>Errors</MenuItem>
        </Select>

        <Button
          variant="contained"
          onClick={() => {
            createLogRecord({
              device_id: 1,
              body: message,
              level: level,
            });
            setMessage("");
          }}
        >
          Generate logrecord
        </Button>

        <Typography variant="h6">Reindex</Typography>
        <LoadingButton variant="contained" onClick={() => reindexFiles()} loading={isLoading}>
          Reindex files
        </LoadingButton>
        <LoadingButton variant="contained" onClick={() => reindexNotes()} loading={isNotesLoading}>
          Reindex notes
        </LoadingButton>

        <Typography variant="h6">Lorem ipsum chat</Typography>
        <LoadingButton variant="contained" onClick={() => setLoremIpsum(loremIpsum === "true" ? "false" : "true")}>
          {loremIpsum === "true" ? "Disable" : "Enable"} lorem ipsum chat
        </LoadingButton>

        <CasbinTest />
      </Stack>
    </Page>
  );
};

export default DeveloperPage;
