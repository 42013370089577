import { useTheme } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface INavItem {
  icon: JSX.Element;
  isActive?: boolean;
  isVisible?: boolean;
  onClick?: () => void;
  position?: "top" | "bottom";
  text: string;
  url?: string;
}

interface NavItemProps {
  item: INavItem;
  expanded: boolean;
}

export default function NavItem({ item, expanded = false }: NavItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isActive = item.url && location.pathname.startsWith(item.url);

  const handleNavigate = useCallback(
    (url: string) => {
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    },
    [navigate],
  );

  return (
    <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        onClick={item.onClick || !item.url ? item.onClick : () => handleNavigate(item.url || "/")}
        sx={{
          minHeight: 48,
          justifyContent: expanded ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expanded ? 3 : "auto",
            justifyContent: "center",
            color: isActive ? "white" : theme.app.nav.textColor,
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{
            fontWeight: isActive ? "bold" : "normal",
            color: isActive ? "white" : theme.app.nav.textColor,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          sx={{ opacity: expanded ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}
