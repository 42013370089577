import { useGetTagsQuery } from "@app/services/appApi";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TagFormDialog from "./TagFormDialog";
import { ITagRead } from "./tagTypes";

interface TagSelectOptions {
  value: string[]; // Tag IDs
  onChange: (value: string[]) => void; // Tag IDs
}

interface AddableTag extends Omit<ITagRead, "created_at" | "updated_at"> {
  inputValue?: string;
}

const filter = createFilterOptions<AddableTag>();

const TagSelect = forwardRef<HTMLDivElement, TagSelectOptions>(({ value, onChange }, ref) => {
  const { t } = useTranslation();

  const { data: options = [] } = useGetTagsQuery();

  const [initialCreateName, setInitialCreateName] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: (AddableTag | string)[]) => {
      // Only set new tag ids if all the tags are valid
      const invalidTag = newValue.find((item) => typeof item === "string" || item.inputValue);

      if (invalidTag !== undefined) {
        setInitialCreateName(typeof invalidTag === "string" ? invalidTag : (invalidTag.inputValue ?? ""));
        setCreateDialogOpen(true);
        return;
      }

      const newTagIds = newValue.map((item) => {
        if (typeof item === "string") {
          // Handle free solo input
          const matchedTag = options.find((tag) => tag.name.toLowerCase() === item.toLowerCase());
          return matchedTag ? matchedTag.id : item; // Return ID if matched, otherwise return the string
        }

        return item.id;
      });

      onChange(newTagIds);
    },
    [onChange, options],
  );

  const handleCreateDialogClose = useCallback(() => setCreateDialogOpen(false), []);

  const selectedTags = useMemo(() => options.filter((tag) => value.includes(tag.id)), [options, value]);

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        freeSolo
        multiple
        defaultValue={[]}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              id: params.inputValue,
              name: t("tags.TagSelect.addNew", { name: params.inputValue }),
              inputValue: params.inputValue,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          return option.name;
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChange}
        options={options as AddableTag[]}
        renderTags={(value: readonly (AddableTag | string)[], getTagProps) =>
          value.map((option: AddableTag | string, index: number) => (
            <Chip color="info" label={typeof option === "string" ? option : option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("tags.TagSelect.label")}
            placeholder={t("Type and press Enter to add tags")}
            inputRef={ref}
          />
        )}
        value={selectedTags || []}
      />
      <TagFormDialog
        initialValues={{ name: initialCreateName }}
        onClose={handleCreateDialogClose}
        onCreate={(tag) => onChange([...value, tag.id])}
        open={createDialogOpen}
      />
    </>
  );
});

TagSelect.displayName = "TagSelect";

export default TagSelect;
