import { Page } from "@core/layout/page";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteTagMutation, useGetTagsQuery } from "../../app/services/appApi";
import { withAuth } from "../../core/auth/authWrappers";
import TagFormDialog from "./TagFormDialog";

const TagListPage = () => {
  const { t } = useTranslation();

  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false);
  const [editTagId, setEditTagId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetTagsQuery();
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteTagMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.tag") }), { variant: "success" });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.tag") }), { variant: "error" });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: t("tags.headings.id"),
        minWidth: 80,
      },
      {
        field: "name",
        headerName: t("tags.headings.name"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        getActions: (params) => [
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("tags.edit")}
            icon={<EditOutlined />}
            onClick={() => setEditTagId(params.row.id)}
          />,
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("tags.delete")}
            icon={<DeleteOutlineOutlined />}
            onClick={() => triggerDelete(params.row.id)}
          />,
        ],
      },
    ],
    [t, triggerDelete],
  );

  const actions = (
    <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setCreateTagDialogOpen(true)}>
      {t("tags.add")}
    </Button>
  );

  return (
    <>
      <Page title={t("tags.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1 }}
          loading={isLoading}
          paginationMode="client"
        />
      </Page>
      <TagFormDialog open={createTagDialogOpen} onClose={() => setCreateTagDialogOpen(false)} />
      <TagFormDialog open={!!editTagId} onClose={() => setEditTagId(undefined)} tagId={editTagId} />
    </>
  );
};

export default withAuth(TagListPage);
