import MyAuthzProvider from "@core/auth/authz/MyAuthzProvider";
import TagListPage from "@features/tags/TagListPage";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import AuthContextProvider from "./core/auth/AuthProvider";
import { Role } from "./core/auth/authWrappers";
import SettingsPage from "./features/Settings";
import { AssistantProvider } from "./features/assistant/useAssistantContext";
import LoginPage from "./features/auth/LoginPage";
import ProfilePage from "./features/auth/Profile";
import DebugPage from "./features/debug/DebugPage";
import DeviceListPage from "./features/device/DeviceListPage";
import FileManagerPage from "./features/file-manager/FileManagerPage";
import NoteListPage from "./features/note/NoteListPage";
import OrganizationListPage from "./features/organization/organizationListPage";
import UserListPage from "./features/user/UserListPage";
import "./i18n";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContextProvider>
          <MyAuthzProvider>
            <AssistantProvider>
              <SnackbarProvider autoHideDuration={4000} />
              <CssBaseline />
              <Routes>
                <Route path="/" element={<AppLayout />}>
                  <Route path="/login" element={<LoginPage />} />

                  <Route index path="/" element={<Navigate to="/devices" />} />
                  <Route path="/devices" element={<DeviceListPage allowedRoles={[Role.User]} />} />
                  <Route path="/notes" element={<NoteListPage allowedRoles={[Role.User]} />} />
                  <Route path="/files" element={<FileManagerPage allowedRoles={[Role.User]} />} />
                  <Route path="/organizations" element={<OrganizationListPage allowedRoles={[Role.User]} />} />
                  <Route path="/users" element={<UserListPage allowedRoles={[Role.User]} />} />
                  <Route path="/tags" element={<TagListPage allowedRoles={[Role.User]} />} />
                  <Route path="/debug" element={<DebugPage />} />
                  <Route path="/profile" element={<ProfilePage allowedRoles={[Role.User]} />} />
                  <Route path="/settings" element={<SettingsPage allowedRoles={[Role.User]} />} />
                </Route>
              </Routes>
            </AssistantProvider>
          </MyAuthzProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
