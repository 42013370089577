import { IChatSemanticSearchToolReturn } from "@app/services/apiTypes";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface ResourceViewerToolbarProps {
  resource?: IChatSemanticSearchToolReturn;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ResourceViewerToolbar = ({ resource, onClose }: ResourceViewerToolbarProps) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
    <Typography variant="h6">{resource?.title}</Typography>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Stack>
);

export default ResourceViewerToolbar;
