import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { IChatSemanticSearchToolReturn } from "../../app/services/apiTypes";
import { useGetNoteQuery } from "../../app/services/appApi";

export interface INoteViewerProps {
  resource: IChatSemanticSearchToolReturn;
}

export default function NoteViewer({ resource }: INoteViewerProps) {
  const { data: note, isLoading, isError } = useGetNoteQuery(resource.src_ref_id);

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        px: 4,
        borderRadius: 1,
        height: "100%",
        overflow: "auto",
      }}
    >
      {isError && (
        <Typography variant="body1" color="error">
          An error occurred while fetching the note
        </Typography>
      )}
      {isLoading && <CircularProgress />}
      {note && <Typography variant="body1" dangerouslySetInnerHTML={{ __html: note.body_html }} />}
    </Box>
  );
}
