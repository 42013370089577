import PdfIcon from "@mui/icons-material/PictureAsPdf";
import NoteIcon from "@mui/icons-material/TextSnippet";
import { Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { IChatSemanticSearchToolReturn } from "../../../app/services/apiTypes";

interface RelevantDocumentProps {
  document: IChatSemanticSearchToolReturn;
  index: number;
  open: (document: IChatSemanticSearchToolReturn) => void;
}

export const RelevantDocument: React.FC<RelevantDocumentProps> = ({ document, index, open }) => {
  const { t } = useTranslation();

  const IconComponent = document.src_ref_type === "STORAGE_FILE" ? PdfIcon : NoteIcon;

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        p: 2,
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
        minWidth: 240,
      }}
      onClick={() => open(document)}
    >
      <Stack direction="row" alignItems="flex-start" gap={1}>
        <IconComponent color="primary" fontSize="small" />
        <Stack gap={0} flex={1} overflow={"hidden"}>
          <Typography
            variant="body1"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {document.title}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {t("assistant.page", { page: document.page_idx + 1 })}
          </Typography>
        </Stack>
        <Typography variant="body1" color="text.secondary">
          #{index + 1}
        </Typography>
      </Stack>
      <Typography
        variant="body2"
        color="GrayText"
        sx={{
          mt: 2,
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 4,
        }}
      >
        {document.content}
      </Typography>
    </Paper>
  );
};
