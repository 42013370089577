import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IChatSemanticSearchToolReturn } from "../../app/services/apiTypes";
import NoteViewer from "./NoteViewer";
import PdfViewer from "./PDFViewer";
import ResourceViewerToolbar from "./ResourceViewerToolbar";

interface ResourceViewerProps {
  resource?: IChatSemanticSearchToolReturn;
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ResourceViewer = ({ resource, open, onClose }: ResourceViewerProps) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        maxWidth: "md",
        maxHeight: "90%",
        backgroundColor: "white",
        borderRadius: 1,
      }}
    >
      <ResourceViewerToolbar resource={resource} onClose={onClose} />
      {resource?.src_ref_type === "STORAGE_FILE" && <PdfViewer resource={resource} />}
      {resource?.src_ref_type === "NOTE" && <NoteViewer resource={resource} />}
    </Box>
  </Modal>
);

export default ResourceViewer;
