import { useCurrentUserQuery, useLoginMutation, useLogoutMutation } from "@app/services/appApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { enqueueSnackbar } from "notistack";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./AuthContext";
import { IUserRead } from "./types";

interface Props {}

const AuthContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const [user, setUser] = useState<IUserRead | null | undefined>(localStorage.getItem("token") ? undefined : null);

  const [login, { data: loginData, isError: isLoginError, isSuccess: isLoginSuccess }] = useLoginMutation();
  const [logout, { isError: isLogoutError, isSuccess: isLogoutSuccess }] = useLogoutMutation();
  const { data, isError: isUserError, isSuccess: isUserSuccess, error: userError } = useCurrentUserQuery();

  useEffect(() => {
    if (isLoginSuccess) {
      enqueueSnackbar("Login successful", { variant: "success" });
    }
  }, [isLoginSuccess, loginData]);

  useEffect(() => {
    if (isLoginError) {
      enqueueSnackbar("Login failed", { variant: "error" });
    }
  }, [isLoginError]);

  useEffect(() => {
    if (isLogoutSuccess) {
      setUser(null);
      enqueueSnackbar("Logout successful", { variant: "success" });
    }
  }, [isLogoutSuccess]);

  useEffect(() => {
    if (isLogoutError) {
      enqueueSnackbar("Logout failed", { variant: "error" });
    }
  }, [isLogoutError]);

  useEffect(() => {
    if (isUserSuccess && data) {
      setUser(data);
    } else if (isUserError && (userError as FetchBaseQueryError).status === 401) {
      setUser(null);
    }
  }, [isUserSuccess, isUserError, data, userError]);

  const value = useMemo(
    () => ({
      user,
      isLoggedIn: user !== null && user !== undefined,
      login,
      logout,
    }),
    [login, logout, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
