import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface AppLogoProps extends React.ComponentProps<typeof Avatar> {}

const LOGO_SIZE = 40;

export const AppLogo: React.FC<AppLogoProps> = ({ sx, ...props }) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        bgcolor: theme.app.logo.square.backgroundColor,
        borderRadius: theme.app.logo.square.borderRadius,
        height: LOGO_SIZE,
        width: LOGO_SIZE,
        ...sx,
      }}
      src={theme.app.logo.square.imgSrc}
      alt="app logo"
      {...props}
    />
  );
};
