import { Page } from "@core/layout/page";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteOrganizationMutation, useGetOrganizationsQuery } from "../../app/services/appApi";
import { withAuth } from "../../core/auth/authWrappers";
import { SimpleSearchBar } from "../../core/input";
import OrganizationFormDialog from "./organizationFormDialog";

const OrganizationListPage = () => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [createOrganizationDialogOpen, setCreateOrganizationDialogOpen] = useState(false);
  const [editOrganizationId, setEditOrganizationId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetOrganizationsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteOrganizationMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.organization") }), {
        variant: "success",
      });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.organization") }), {
        variant: "error",
      });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: "id",
        headerName: t("organizations.headings.id"),
        minWidth: 80,
      },
      {
        field: "name",
        headerName: t("organizations.headings.name"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        getActions: (params) => [
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("organizations.edit")}
            icon={<EditOutlined />}
            onClick={() => setEditOrganizationId(params.row.id)}
          />,
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("organizations.delete")}
            icon={<DeleteOutlineOutlined />}
            onClick={() => triggerDelete(params.row.id)}
          />,
        ],
      },
    ],
    [t, triggerDelete],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => setCreateOrganizationDialogOpen(true)}
      >
        {t("organizations.add")}
      </Button>
    </>
  );

  return (
    <>
      <Page title={t("organizations.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results || []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1 }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <OrganizationFormDialog
        open={createOrganizationDialogOpen}
        onClose={() => setCreateOrganizationDialogOpen(false)}
      />
      <OrganizationFormDialog
        open={!!editOrganizationId}
        onClose={() => setEditOrganizationId(undefined)}
        organizationId={editOrganizationId}
      />
    </>
  );
};

export default withAuth(OrganizationListPage);
