import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SimpleSearchBarProps extends React.ComponentProps<typeof TextField> {
  onSearch: (searchValue: string) => void;
}

const SimpleSearchBar: React.FC<SimpleSearchBarProps> = ({ onSearch, ...rest }: SimpleSearchBarProps) => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(searchValue);
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <TextField
        placeholder="Cerca qualcosa..."
        inputProps={{ "aria-label": "search" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" aria-label="search">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
        size="medium"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        {...rest}
      />
    </form>
  );
};

export { SimpleSearchBar };
export type { SimpleSearchBarProps };
