import { useAuthorization } from "@core/auth/authz/useAuthorization";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function CasbinTest() {
  const { can, cannot } = useAuthorization();

  return (
    <>
      <Typography variant="body1" component="p">
        Can I read data1: {can("read", "data1").toString()}
      </Typography>
      <Typography variant="body1" component="p">
        Can I write data1: {can("write", "data1").toString()}
      </Typography>

      <Typography variant="body1" component="p">
        Can I view USERS_PAGE: {can("view", "USERS_PAGE").toString()}
      </Typography>

      <Tooltip title={cannot("write", "data1") ? "You are not authorized to write data1" : "Click to write data1"}>
        <span>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={cannot("write", "data1")}
          >
            Write
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
