import { useDeleteNoteMutation, useGetNotesQuery } from "@app/services/appApi";
import { withAuth } from "@core/auth/authWrappers";
import { SimpleSearchBar } from "@core/input";
import { Page } from "@core/layout/page";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NoteFormDialog from "./NoteFormDialog";
import { useAuthorization } from "@core/auth/authz/useAuthorization";

const NoteListPage = () => {
  const { can } = useAuthorization();

  const { t } = useTranslation();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [createNoteDialogOpen, setCreateNoteDialogOpen] = useState(false);
  const [editNoteId, setEditNoteId] = useState<string | undefined>(undefined);

  const { data, isLoading } = useGetNotesQuery(
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search: searchValue,
    },
    { pollingInterval: 3000 },
  );
  const [triggerDelete, { isError, isSuccess, reset: resetDelete }] = useDeleteNoteMutation();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("notifications.modelDelete.success", { model: t("models.note") }), { variant: "success" });
      resetDelete();
    }
    if (isError) {
      enqueueSnackbar(t("notifications.modelDelete.failure", { model: t("models.note") }), { variant: "error" });
    }
  }, [isSuccess, isError, t, resetDelete]);

  const getIndexStatusColor = (status: string) => {
    switch (status) {
      case "QUEUED":
        return "info.main";
      case "PENDING":
        return "warning.main";
      case "COMPLETED":
        return "success.main";
      case "FAILED":
        return "error.main";
      default:
        return "text.primary";
    }
  };

  const columns = useMemo(
    (): (GridColDef & { isVisible?: boolean })[] => [
      {
        field: "id",
        headerName: t("notes.headings.id"),
        minWidth: 80,
      },
      {
        field: "title",
        headerName: t("notes.headings.title"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "tags",
        headerName: t("notes.headings.tags"),
        flex: 1,
        minWidth: 140,
        valueGetter: (params) => params.row.tags.map((tag: any) => tag.name).join(", "),
      },
      {
        field: "body",
        headerName: t("notes.headings.body"),
        flex: 1,
        minWidth: 200,
      },
      {
        field: "index_status",
        headerName: t("notes.headings.indexStatus"),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => (
          <Typography variant="body2" color={getIndexStatusColor(params.value)}>
            {t(params.value)}
          </Typography>
        ),
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 80,
        isVisible: can("UPDATE", "DEVICE") && can("DELETE", "DEVICE"),
        getActions: (params) => [
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("notes.edit")}
            icon={<EditOutlined />}
            onClick={() => setEditNoteId(params.row.id)}
          />,
          <GridActionsCellItem
            closeMenuOnClick
            showInMenu
            label={t("notes.delete")}
            icon={<DeleteOutlineOutlined />}
            onClick={() => triggerDelete(params.row.id)}
          />,
        ],
      },
    ],
    [t, triggerDelete, can],
  );

  const actions = (
    <>
      <SimpleSearchBar onSearch={setSearchValue} size="small" />
      {can("CREATE", "DEVICE") && (
        <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => setCreateNoteDialogOpen(true)}>
          {t("notes.add")}
        </Button>)}
    </>
  );

  return (
    <>
      <Page title={t("notes.title")} actions={actions}>
        <DataGrid
          disableRowSelectionOnClick
          rows={data?.results || []}
          columns={columns.filter((column) => column.isVisible !== false)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          sx={{ flex: 1 }}
          loading={isLoading}
          paginationMode="server"
          rowCount={data?.count || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </Page>
      <NoteFormDialog open={createNoteDialogOpen} onClose={() => setCreateNoteDialogOpen(false)} />
      <NoteFormDialog open={!!editNoteId} onClose={() => setEditNoteId(undefined)} noteId={editNoteId} />
    </>
  );
};

export default withAuth(NoteListPage);
