import { Close, DriveFolderUploadOutlined, InsertDriveFileOutlined, UploadFileOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { DragEventHandler, useCallback, useState } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useMultiDrop } from "react-dnd-multi-backend";
import { useTranslation } from "react-i18next";
import { readDirectory, removeFilenameFromPath } from "./utils";

interface DropZoneProps {
  isActive: boolean;
  hasFile: boolean;
}

const DropZone = styled(Box)<DropZoneProps>(({ theme, isActive, hasFile }) => ({
  border: `2px dashed ${isActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  backgroundColor: isActive
    ? alpha(theme.palette.primary.light, 0.1)
    : hasFile
      ? alpha(theme.palette.primary.light, 0.05)
      : "transparent",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

interface FileUploadDialogProps {
  isUploadingFile?: boolean;
  open: boolean;
  onClose: () => void;
  onUpload: (file: File, silent?: boolean) => Promise<void>;
}

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ isUploadingFile = false, open, onClose, onUpload }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [files, setFiles] = useState<File[]>([]);

  const appendFiles = useCallback((newFiles: File[]) => {
    setFiles((prevFiles) =>
      [
        ...prevFiles.filter((file) => file.type === "application/pdf"),
        ...newFiles.filter((file) => file.type === "application/pdf"),
      ].filter((file, index, self) => self.findIndex((f) => f.name === file.name) === index),
    );
  }, []);

  const handleNativeDrop: DragEventHandler<HTMLDivElement> = useCallback(
    async (event) => {
      event.preventDefault();

      const newFiles: File[] = [];

      const dataTransferItemList = event.dataTransfer.items;
      const dataTransferItems: DataTransferItem[] = Array.from(dataTransferItemList);

      for (const dataTransferItem of dataTransferItems) {
        const entry = dataTransferItem.webkitGetAsEntry ? dataTransferItem.webkitGetAsEntry() : null;

        if (!entry || entry.isFile) {
          const file = dataTransferItem.getAsFile();

          if (file?.type === "application/pdf") {
            newFiles.push(file);
          }
        } else if (entry.isDirectory) {
          const directoryFiles = await readDirectory(entry as FileSystemDirectoryEntry);
          newFiles.push(...directoryFiles);
        }
      }

      appendFiles(newFiles);
    },
    [appendFiles],
  );

  const [[{ canDrop: canDropFiles, isOver: isOverFiles }, dropFiles]] = useMultiDrop({
    accept: [NativeTypes.FILE],
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [[{ canDrop: canDropDirectories, isOver: isOverDirectories }, dropDirectories]] = useMultiDrop({
    accept: [NativeTypes.FILE],
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleFilesSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files
        ? Array.from(event.target.files).filter((file) => file.type === "application/pdf")
        : [];

      if (selectedFiles && selectedFiles.length) {
        appendFiles(selectedFiles);
      } else {
        enqueueSnackbar(t("fileManager.fileUploadDialog.onlyPdfFilesSupported"), { variant: "error" });
      }
    },
    [appendFiles, t],
  );

  const handleDirectoriesSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files
        ? Array.from(event.target.files)
            .filter((file) => file.type === "application/pdf")
            .map(
              (file) =>
                new File([file], file.webkitRelativePath.trim() === "" ? file.name : file.webkitRelativePath, {
                  type: file.type,
                }),
            )
        : [];

      if (selectedFiles && selectedFiles.length) {
        appendFiles(selectedFiles);
      } else {
        enqueueSnackbar(t("fileManager.fileUploadDialog.noPdfFilesInTheFolder"), { variant: "error" });
      }
    },
    [appendFiles, t],
  );

  const handleUpload = useCallback(async () => {
    if (files && files.length) {
      const directories = files
        .filter((file) => file.name.includes("/"))
        .map((file) => removeFilenameFromPath(file.name))
        .flatMap((directory) => {
          const parts = directory.split("/");
          return parts.map((_, index) => parts.slice(0, index + 1).join("/"));
        })
        .filter((directory, index, self) => self.findIndex((d) => d === directory) === index);

      for (const directory of directories) {
        await onUpload(new File([""], `${directory}/.directory`), true);
      }

      for (const file of files) {
        await onUpload(file, true);
      }

      if (files.length > 1) {
        enqueueSnackbar(t("fileManager.fileUploadDialog.uploadFilesSuccess"), { variant: "success" });
      } else {
        enqueueSnackbar(t("fileManager.fileUploadDialog.uploadFileSuccess"), { variant: "success" });
      }

      setFiles([]);
      onClose();
    }
  }, [files, onClose, onUpload, t]);

  const isActiveFiles = canDropFiles && isOverFiles;
  const isActiveDirectories = canDropDirectories && isOverDirectories;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("fileManager.fileUploadDialog.title")}</DialogTitle>
      <DialogContent>
        <input
          multiple
          accept="application/pdf"
          id="file-input"
          onChange={handleFilesSelect}
          style={{ display: "none" }}
          type="file"
        />
        <input
          id="directory-input"
          onChange={handleDirectoriesSelect}
          style={{ display: "none" }}
          type="file"
          // @ts-ignore
          webkitdirectory="true"
        />
        <Stack gap={1} direction={isMobile ? "column" : "row"} justifyContent="center">
          <InputLabel htmlFor="file-input" sx={{ flex: 1 }}>
            <DropZone
              ref={dropFiles}
              hasFile={false}
              isActive={isActiveFiles}
              onDrop={handleNativeDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <UploadFileOutlined sx={{ fontSize: 48, mb: 2 }} />
              <Typography variant="h6" component="div" gutterBottom>
                {t("fileManager.fileUploadDialog.dragAndDropFile")}
              </Typography>
              <Typography variant="body2">{t("fileManager.fileUploadDialog.clickToSelect")}</Typography>
            </DropZone>
          </InputLabel>
          <InputLabel htmlFor="directory-input" sx={{ flex: 1 }}>
            <DropZone
              ref={dropDirectories}
              hasFile={false}
              isActive={isActiveDirectories}
              onDrop={handleNativeDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <DriveFolderUploadOutlined sx={{ fontSize: 48, mb: 2 }} />
              <Typography variant="h6" component="div" gutterBottom>
                {t("fileManager.fileUploadDialog.dragAndDropFolder")}
              </Typography>
              <Typography variant="body2">{t("fileManager.fileUploadDialog.clickToSelect")}</Typography>
            </DropZone>
          </InputLabel>
        </Stack>
        <Stack gap={1} mt={1}>
          {files.map((file) => (
            <Card key={file.name} variant="outlined" sx={{ display: "flex", alignItems: "center", padding: 1 }}>
              <InsertDriveFileOutlined sx={{ fontSize: 18, mr: 1 }} />
              <Typography
                sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                title={file.name}
                variant="subtitle2"
              >
                {file.name}
              </Typography>
              <IconButton onClick={() => setFiles((prevFiles) => prevFiles.filter((f) => f !== file))}>
                <Close sx={{ fontSize: 18 }} />
              </IconButton>
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("fileManager.fileUploadDialog.cancel")}</Button>
        <LoadingButton
          color="primary"
          disabled={!files}
          loading={isUploadingFile}
          onClick={handleUpload}
          variant="contained"
        >
          {t("fileManager.fileUploadDialog.upload")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
