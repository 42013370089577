import { IUserRead } from "@core/auth/types";
import { IDeviceRead } from "@features/device/deviceTypes";
import { INoteRead } from "@features/note/noteTypes";

export interface IListResponse<T> {
  count: number;
  results: T[];
}

export interface ISearchRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  infiniteScroll?: boolean;
}

export interface ITopKRequest {
  query: string;
  device_id: IDeviceRead["id"];
}
export type ITopKResponse = IListResponse<ITopKResult>;

export interface ITopKResult {
  src_ref_type: "NOTE" | "STORAGE_FILE";
  src_ref_id: string;
  score: number;
  title: string;
  content: string;
  page_idx: number;
  presigned_url: string;
}

//TODO: move types down below in their own feature directories

/**
 * ILogRecord is the type used for log records.
 * it contains all the information about a log record
 * as returned by the API.
 */
export interface ILogRecord {
  id: number;
  body: string;
  level: "info" | "warning" | "error";
  device: Pick<IDeviceRead, "name">;
  note: Pick<INoteRead, "body"> | null;
  created_at: string;
}

/**
 * ILogRecordRequest is used for creating a new log record.
 * It is a subset of ILogRecord.
 */
export interface ILogRecordRequest extends Pick<ILogRecord, "body" | "level"> {
  device_id: number;
}

export interface IRelatedLogRecordResult {
  log_record: ILogRecord;
  match: "exact" | "similar";
  score: number;
}

export interface IChat {
  id: number;
  messages: IChatMessage[];
}

export interface IDebugResponse {
  name: string;
  tagline: string;
  version: string;
  hostname: string;
}

export enum ChatAuthorType {
  Agent = "agent",
  ToolExecutor = "tool_executor",
  User = "user",
}

/**
 * IChatMessage is the type used for chat messages.
 * it contains all the information about a chat message
 * as returned by the API.
 */
export interface IChatTextMessage {
  author: Pick<IUserRead, "id" | "username"> | null;
  author_type: "user" | "agent";
  body: string;
  chat: Pick<IChat, "id">;
  context_device: Pick<IDeviceRead, "id" | "name"> | null;
  created_at: string;
  downvotes: number;
  id: number;
  upvotes: number;
}

export enum ToolType {
  SemanticSearch = "semantic_search",
}

interface IChatSemanticSearchToolParam {
  query: string;
  value: string;
}

export interface IChatSemanticSearchToolReturn {
  citation_id: string;
  content: string;
  page_idx: number;
  presigned_url: string;
  src_ref_id: string;
  src_ref_type: "NOTE" | "STORAGE_FILE";
  title: string;
}

export interface IChatSemanticSearchTool {
  type: ToolType;
  params: IChatSemanticSearchToolParam[];
  return: IChatSemanticSearchToolReturn[];
}

export type IChatUsedTool = IChatSemanticSearchTool;

export interface IChatToolMessage {
  author_type: "tool_executor";
  used_tools: IChatUsedTool[];
}

export type IChatMessage = IChatTextMessage | IChatToolMessage;

/**
 * IChatMessageRequest is used for creating a new chat message.
 * It is a subset of IChatMessage.
 */
export interface IChatMessageRequest extends Pick<IChatTextMessage, "body"> {
  context_log_record_id?: number;
  context_device_id: IDeviceRead["id"];
  language: Language;
}

export type Language = "en" | "it";
