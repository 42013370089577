import { Stack, Typography } from "@mui/material";
import React from "react";

interface PageProps {
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ title, actions, children }) => {
  return (
    <>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 2,
          flexWrap: {
            xs: "wrap",
            sm: "wrap",
            md: "nowrap",
            lg: "nowrap",
            xl: "nowrap",
          },
        }}
      >
        <Typography noWrap variant="h5" component="h1" sx={{ flex: 1 }}>
          <b>{title}</b>
        </Typography>
        <Stack direction="row" gap={2} alignItems="center" flexWrap={"wrap"}>
          {actions}
        </Stack>
      </Stack>
      {children}
    </>
  );
};

export { Page };
